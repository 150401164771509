import './index.scss';
import { Component } from 'react';
import { connect } from 'react-redux';
import { isMobile } from '../../../utils/utils';

class DualOptionSelector extends Component{
    constructor(props){super(props);
    this.state = {active : 0}}
    
    componentDidMount(){
        const {  keyItem, parentField, form, options} = this.props;
        
        
        if(!form[parentField][keyItem] || form[parentField][keyItem] == ''){
            this.setState({active : -1});
        }else{
            const index = options.indexOf(form[parentField][keyItem]);
            this.setState({active : index});
        }
    }    
    
    changeSelector = (index) => {
        const { options, parentField, keyItem, canDeselect } = this.props;
        const { active } = this.state;
        let newValue = '';

        if(canDeselect && active == index){
            this.setState({active : -1});
        }else{
            this.setState({active : index});
            newValue = options[index];
        }
        this.props.onChange(keyItem, newValue, parentField);
    }
    
    render = () => {
        const { options, title, parentField,isRequired, form, keyItem} = this.props;
        const { active } = this.state;
    
        const mobile = isMobile(window);
        return (
            <div className='box-container no-border no-padding' style={{marginTop : 16}}>
                {title ? 
                <span className='inline'>
                    <h5 className={'title bold-text'}>{title}</h5>
                    {isRequired ? <h5 className='!text-red-600 !pt-0 !pb-0 !pl-1 !pr-0'>*</h5> : null}
                </span>  
                : <div/>
                }             
                <div className='dual-option-container'>
                        <div className='grid-50'>
                            {options.map( (option, index) => {
                                return (
                                    <>
                                            {mobile ? 
                                            <h5 className={`${active == index ? 'active' : 'not-active'}`} 
                                            onClick={() => this.changeSelector(index)}>{option}</h5>
                                            :
                                            <h5 className={`${active == index ? 'active' : 'not-active'}`} 
                                            onClick={() => this.changeSelector(index)}>{option}</h5>}
                                    </>);
                            })}
                        </div>
                        <div className='absolute left-1/2' style={{height: "80%", bottom: "10%"}}>
                            <div className='divider-vertical' />   
                        </div>
                        
                </div>
            </div>
        )
    }
    
}

export default DualOptionSelector;
