import './index.scss';
import { Component } from 'react';
import { connect } from 'react-redux';
import Loading from '../../components/Loading';
import { MetaTags } from 'react-meta-tags';
import Header from '../../components/Header';
import ThreeItems from '../../sections/ThreeItems';
import Footer from '../../components/Footer';
import FilterView from '../../sections/FilterView';
import CarsListVertical from '../../sections/CarsList/Vertical';
import ConnectionAPI from '../../api';
import { getObjectFromQueryString, getQueryStringFromObject } from '../../lib/Helpers';
import { scrollToTopPage } from '../Utils/utils';
import { withTranslation } from 'react-i18next';
import { isMobile } from '../../../utils/utils';
import ContainedLoading from '../../components/ContainedLoading';
import { pixelCarListPage } from '../../../utils/pixel';
import { mainFormSearch } from '../../../forms/mainsearch';
import { getFilterValues } from '../../../filters/search';

class BuyPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            cars: [],
            total: 0,
            filters : {},
            totalPages: 0,
        };
    }

    componentDidMount = async () =>  {
        //always start at the top
        scrollToTopPage();

        pixelCarListPage();

        /* Get all filters */
        /* Access Form State coming from Query */
        const queryFilters = getObjectFromQueryString({queryString : window.location.search});
        
        const newFilters = [];
        const defaultFilters = mainFormSearch();
        for(let key in defaultFilters.filters){
            newFilters[key] = defaultFilters.filters[key];
        }
        newFilters.page = 1;

        //override the default filter with the filters from search bar and also with sort and page
        for(let key in queryFilters){
            newFilters[key] = queryFilters[key];
        }

        /* Set Form State */
        Object.keys(newFilters).forEach(x => {
            this.state.filters[x] = newFilters[x];
        });
        await this.getCars();
    }

    getCars = async () => {
        const { t, i18n } = this.props;
        const { filters } = this.state;
        
        let searchFilters = { ...filters };
        searchFilters.offset = (searchFilters.page-1) * 20;

        this.setState({ loading: true });
        try {
            
            const response = await ConnectionAPI.searchCars({ 
                filters: await getFilterValues(t,i18n.language,searchFilters) });

            const { cars, total } = response.message;
            const totalPages = Math.ceil(total / 20); // Assuming 20 cars per page

            let newUrl = new URL(window.location);
            newUrl.search = getQueryStringFromObject({filters: searchFilters});

            window.history.pushState(null,'', newUrl);
            
            setTimeout(() => {
                this.setState({ cars, loading: false, total, totalPages, filters: searchFilters });    
               scrollToTopPage();
            }, 1000);
        } catch (error) {
            console.log(error);
            this.setState({ loading: false });
        }
    }

    onChangePage = (event, page) => {
        this.state.filters.page = page;
        this.getCars(page);
    }
    
    changeSort = (sort) => {
        this.state.filters.sort = sort;
        this.getCars();
    }

    changeVehicleFilter = async (newVehicleFilters) => {
        Object.keys(newVehicleFilters).forEach(x => {
            this.state.filters[x] = newVehicleFilters[x];
        });
        
        await this.getCars();
    }

    render() {
        const { t } = this.props;
        const { loading, cars, total, totalPages, filters } = this.state;

        const mobile = isMobile(window);
        return (
            <>
                {/* {loading && <Loading />} */}
                <MetaTags>
                    <title> Versicar </title>
                    <meta name="description" content="Buy your best car" />
                    <meta property="og:title" content="Versicar" />
                </MetaTags>
                <Header />
                <div className='box-background section'>
                    <div className={`${mobile ? '' : 'grid-filter-car sub'}`}>
                        <FilterView filters={filters} changeVehicleFilter={this.changeVehicleFilter} initialCount={total} />
                        {
                             loading
                             ?
                             <ContainedLoading transparentBackground={true}/>
                             :
                            <CarsListVertical
                                changeSort={this.changeSort}
                                title={t('buy_1')}
                                total={total}
                                cars={cars}
                                loading={loading}
                                currentPage={filters.page}
                                totalPages={totalPages}
                                onChangePage={this.onChangePage}
                                initialSortIndex={this.state.filters.sort - 1}
                            />
                        }
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state) {
    const user = state.user;
    return { user };
}

export default connect(mapStateToProps)(withTranslation()(BuyPage));