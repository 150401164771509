import React,{ Component } from 'react';
import { connect } from 'react-redux';
import './index.scss'
import variables from '../../../../_export.scss';
import { IconButton } from '@material-ui/core';
import { CloseOutlined, KeyboardArrowDown } from '@mui/icons-material';
import Button from '../../Button';
import { MOBILE_WIDTH } from '../../..';
import { withTranslation } from 'react-i18next';
import ContainedLoading from '../../ContainedLoading';
import { createRoot } from 'react-dom/client';
import zIndex from '@material-ui/core/styles/zIndex';

class ContainerDropDown extends Component{
    constructor(props){
        super(props);
        const { keyItem, id } = this.props;

        this.state = {
            isSelected : false,
            canOpenMenu: true,
            observer: null,
            dropDownWidth: -1
        }
        this.dropDownParentRef = React.createRef();
        this.dropDownRef = React.createRef();
        this.buttonRef = React.createRef();
    }

	handleChange = (event) => {
		this.setState({item : event.target.value});
	};
    
    changeDropDownStatus = () => {
        this.setState({isSelected : !this.state.isSelected}, () => {
            if(this.dropDownRef.current && this.state.dropDownWidth != -1){
                this.dropDownRef.current.remove();
                this.dropDownRef.current = null;
            }
        });
    }
    
    openDropDownStatus = (e) => {
        const{ id,keyItem } = this.props;

        if(this.state.canOpenMenu){
            this.setState({isSelected : true});
            document.getElementById(`modal-${this.props.keyItem}-${id}`).classList.replace('hideModal', "showModal");


            let comp = document.getElementById("input" + keyItem);
            if(comp){
                comp.focus();
            }
        }
        this.setState({canOpenMenu : true});
    }
    
    emptyDropDown = (event) => {
       this.props.emptyDropDown(this.props.keyItem);
       this.props.onSearchChange('');
       this.setState({isSelected : false});
       event.stopPropagation();
    }

    realocateDropDownLocation = () => {
        //check if the sub menu wont go over the screen
        //if it will make sure it is position at the left
        //in order to do this the submenu must be detache from the actual select and become absolute as a children of the parent where
        //the select component is present
        //in order to calculate this always assume the submenu will have max-width (children-container -> 240px)

        //if dropDownWidth != -1 it means the child as already set and deleted previously
        let rect = this.dropDownParentRef.current.children.length > 0 ?
                    this.dropDownParentRef.current.children[0].getBoundingClientRect()
                    : null;
        if (
            (rect && this.dropDownParentRef.current && rect.right >= window.screen.availWidth)
                || 
            this.state.dropDownWidth != -1){
                
            //delete child
            if(this.dropDownParentRef.current.children.length > 0){
                this.dropDownParentRef.current.children[0].remove();
                this.dropDownRef.current.remove()
                this.dropDownRef.current = null;
            }

            const selectParent = this.buttonRef.current.parentElement.parentElement.parentElement.parentElement.parentElement;            
            //the element must be at the x-150 (give just 5px as buffer)
            const container = document.createElement("div"); // Create a container
            Object.assign(container.style, {
                position: "absolute",
                marginTop: "50px",
                left: window.screen.availWidth - (rect ? rect.width : this.state.dropDownWidth) - 5 + "px",
                zIndex: "500"
            });
            const root = createRoot(container);
            root.render(this.renderDropDown());
            selectParent.append(container);

            if(rect)
                this.state.dropDownWidth = rect.width;
        }
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.onFocusLost);
        document.addEventListener("mouseup", this.onFocusGet);

        this.state.observer = new MutationObserver(() => {
            const computedStyle = window.getComputedStyle(this.dropDownParentRef.current);
            if (computedStyle.display !== "none") {
                this.realocateDropDownLocation();
            } else {
                console.log("Modal is invisible!");
            }
          });
        this.state.observer.observe(this.dropDownParentRef.current, { attributes: true, attributeFilter: ["style", "class"] });
    }
    
    componentWillUnmount() {
        document.removeEventListener("mousedown", this.onFocusLost);
        document.removeEventListener("mouseup", this.onFocusGet);
        this.state.observer.disconnect();
    }

    onFocusLost = (e) => {
        const { isSelected,dropDownId } = this.state;

        let isClosing = false;
        if (this.dropDownRef.current && !this.dropDownRef.current.contains(e.target) && isSelected) {
            this.changeDropDownStatus();
            isClosing = true;

            //check if the menu that was open was outside bondaries
            if(this.state.dropDownWidth != -1){
                this.dropDownRef.current.remove();
                this.dropDownRef.current = null;
            }
        }

        if (this.buttonRef.current && this.buttonRef.current.contains(e.target) && isClosing) {
            this.setState({ canOpenMenu: false });
        }
    };

    onFocusGet = (e) => {
        //the user cannot open the menu if the menu was opened and the user clicked again on the menu button
        if (this.buttonRef.current && !(this.buttonRef.current.contains(e.target) && !this.state.canOpenMenu))
            this.setState({ canOpenMenu: true });
    }

    onSearchChange = (event) => {
        this.props.onSearchChange(event.target.value);
    }

    renderDropDown = () => {
        const { allowSmallContainer, isSearch, isLoading} = this.props;
        return (
            <div ref={this.dropDownRef} className={`div-selectBody modal ${allowSmallContainer ? '!min-w-0' : ''}`} >
            {
            isLoading?
            <ContainedLoading/>
            :
            <div>
                <div className='children-container'>
                    {this.props.children}
                </div>
                
                <div className='clear-box-container'>
                {allowSmallContainer ?
                <></> 
                :
                <Button onClick={this.changeDropDownStatus} className='btn close-button thin-btn button'>
                    <h5 className=''>Fechar</h5>
                </Button>
                }
                </div>                            
            </div>
            }
        </div>
        );
    }

    render = () => {
		const { isSearch, form, keyItem, itemSelected, id,
             placeholder, parentField, removeX=false, isLoading, isBorderless,
            hasDropDownArrow, allowSmallContainer} = this.props;
        const { isSelected } = this.state;

        var filters;
        if(parentField){
            filters = form[parentField];
        }else{
            filters = form;
        }
        let isActive = (filters[keyItem] != '' || filters[keyItem].length > 0);
        if(removeX){
            isActive = false;
        }
        
        let isMobile = window.innerWidth < MOBILE_WIDTH;
        return (
			<div className='container-multiSelect modal-selector'>
                    <div ref={this.buttonRef} className={`selected-button ${isBorderless ? 'no-border' : ''} ${isActive && !isSelected ? 'activeButton' : null}`} onClick={this.openDropDownStatus}>
                        {itemSelected && !isSelected ? 
                            <h5 className='inline'>{itemSelected}</h5>
                        : (
                            isSearch && isSelected ? 
                            <input autoComplete='off' autoFocus={true} name='search' type='text' 
                                id={"input" + keyItem} onChange={this.onSearchChange} 
                                        className="item-input"/>
                            // <h5 className='inline'>{itemSelected}</h5>

                            
                            :
                            (
                                isSelected ?
                                    <h5 className='inline'>{itemSelected}</h5>
                                :
                                <h5 className='inline placeholder'>
                                    {isMobile ? <div className='icon-multiSelect grey'>{placeholder}</div> : placeholder}
                                </h5>
                            )
                        )
                        }
                        {
                            isSearch && isSelected ?
                            <>
                            </>
                            :
                            (
                            isActive && !removeX ? 
                                <CloseOutlined onClick={this.emptyDropDown} className='no-margin h-32' style={{color : variables.backgroundColor}} />
                            :
                            <>
                            {hasDropDownArrow ?  
                                <KeyboardArrowDown className='no-margin h-32' style={{color : variables.textColor}}/>
                            :
                            <></>
                            }
                            </>
                            )
                        }
                    </div>
                    <div ref={this.dropDownParentRef} id={`modal-${keyItem}-${id}`} className={`no-margin no-padding ${isSelected && isSearch ? '!mt-6' : ''} ${isSelected ? 'showModal' : 'hideModal'}`}>
                        {this.renderDropDown()}
                    </div>
		  	</div>
        )
    }
    
}

export default ContainerDropDown;
