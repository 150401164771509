/* Create FAQ Container with Questions and Answers */
import React from 'react';
import './index.scss';
import Button from '../../components/Button';
import InformationCard from '../../components/InformationCard'
import { useTranslation } from 'react-i18next';
import { isMobile } from '../../../utils/utils';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { pixelUserMobilePhone } from '../../../utils/pixel';
import ImagedCard from '../../components/ImagedCard';

//the background type must be a css class defined in the themes. only the name is necessary
//that is, if the class is called primary-background you only need to pass primary
//if no background type is passed the primary-background will be used as default
const FAQContainer = ({backgroundType, hideKnowMore = false}) => {
    if(!backgroundType){
        backgroundType = 'primary'
    };
    backgroundType += '-background';
    const { t, i18n } = useTranslation();

    const faq = []
    for(let i = 2; i <= 19; i+=2){
        faq.push(
            {
                question : t('faq_' + i),
                answer : t('faq_' + (i + 1))
            },
        )
    }

    const mobile = isMobile(window);
    return (
        <div className={`section ${backgroundType}`}>
            <div className='container'>
                <h2 className='center small-container'> {t('faq_1')} </h2>
                <div className="block">
                    {faq.map((item, index) => {
                        return <Accordion className='mb-5 accordion-style'>
                            <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1-content">
                                <h3 className='title-bold'>{item.question}</h3>
                            </AccordionSummary>
                            <AccordionDetails>
                                <h4>{item.answer}</h4>
                            </AccordionDetails>
                        </Accordion>
                    })}
                </div>
                        
                <center> 
                    <ImagedCard title={t('faq_20')}
                        text={<a className='no-margin' href='tel:+351914419292'>
                            <h6>{t('contact_us')}</h6>
                        </a>}/>
                    {/* <h4>{t('faq_20')}</h4>
                    <Button className='button fit ml-5' onClick={() => pixelUserMobilePhone()}>
                        <a className='no-margin' href='tel:+351914419292'>
                            <h5 className='text-white'>{t('contact_us')}</h5>
                        </a>
                    </Button> */}
                </center>
            </div>
        </div>
    )
}

export default FAQContainer;