/* Create Hero Section with Image and Text */
import React, {useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import './index.scss';
import MultiSelect from '../../components/DropDown/MultiSelect';
import CheckBox from '../../components/CheckBox';
import { hero_2 } from '../../../assets/images';
import { CarCrashOutlined, CarRentalOutlined, ChatBubbleOutline, ChatOutlined, EnhancedEncryptionOutlined, FavoriteBorderOutlined, PriceChangeOutlined, SupportOutlined } from '@mui/icons-material';
import { mainFormSearch } from '../../../forms/mainsearch';
import { getQueryStringFromObject } from '../../lib/Helpers';
import { useTranslation } from 'react-i18next';


const electricsForm = mainFormSearch();

const ElectricsHero = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    return (
        <div>
            <div className='eletric-hero'>
                <div className='background'></div>
                <img src={hero_2} alt='hero' className='hero-image'/>
                <div className='content'>
                    <div className='container'>
                        <h4 className='main-hero-title grey bold-text'>{t('hybrid_1')}</h4>
                        <h2 className='main-hero-subtitle'>{t('hybrid_2')}</h2>
                        <h5 className='small-container grey'>{t('hybrid_3')}</h5>
                        <Button className="button fit left !bg-white">
                            <h5 className='bold-text' onClick={(e) => {
                                electricsForm.filters.fuel = t('eletric');
                                const query = getQueryStringFromObject({filters : electricsForm.filters});
                                navigate(`/buy?${query}`);
                            }}>{t('hybrid_4')}</h5>
                        </Button>
                    </div>
                    <div className='grid-33 container'>
                        <div className='small-container'>
                            <div style={{width : 60, height : 60}} className='small-container'>
                                <CarRentalOutlined className='icon' />
                            </div>
                            <h4 className='bold-text'>{t('hybrid_5')}</h4>
                            <h5 className='small-container'>{t('hybrid_6')}</h5>
                        </div>
                         <div className='small-container'>
                            <div style={{width : 60, height : 60}} className='small-container'>
                                <PriceChangeOutlined className='icon'/>
                            </div>
                            <h4 className='bold-text'>{t('hybrid_7')}</h4>
                            <h5 className='small-container'>{t('hybrid_8')}</h5>
                        </div>
                         <div className='small-container'>
                            <div style={{width : 60, height : 60}} className='small-container'>
                                <SupportOutlined className='icon'/>
                            </div>
                            
                            <h4 className='bold-text'>{t('hybrid_9')}</h4>
                            <h5 className='small-container'>{t('hybrid_10')}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}   

export default ElectricsHero;