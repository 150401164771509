import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import Image from '../Image';
import './index.scss';
import { hamburgerMenu, logo } from '../../../assets/images';
import UrlNavigatorSingleton from '../../lib/UrlNavigator';
import { ROUTES } from '../../route';
import { AccountBoxOutlined, Close, Euro, FavoriteBorderOutlined, KeyboardArrowDown, Person2Outlined } from '@mui/icons-material';
import ConnectionAPI from '../../api';
import Button from '../Button';
import UserAPI from '../../lib/User';
import { useTranslation } from 'react-i18next';
import { isMobile } from '../../../utils/utils';
import { Helmet } from 'react-helmet';
import { IS_PRODUCTION } from '../../../config';
import { scrollToTopPage } from '../../pages/Utils/utils';
import { pixelBuyPageFromHeader } from '../../../utils/pixel';
import { availableLanguages, defaultLanguage, defaultLanguageKey } from '../../../i18next';
import Flag from 'react-world-flags';
import SelectDropDown from '../DropDown/Select';

const Header = React.memo((props) => {
    const location = useLocation();
    const [favorites, setFavorites] = React.useState(0);
    const { t, i18n } = useTranslation();
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
    const [form, setForm] = useState({"filters" : {"language" : defaultLanguage}});


    React.useEffect(() => {
        /* Verify if Logged in */
        login();
    }, []);

    const login = async () => {
        let isAuth = await UserAPI.auth();
        if(isAuth)
            getFavorites();
    }

    const getFavorites = async () => {
        const response = await ConnectionAPI.getFavorites();
        if (response.status === 200) {
            setFavorites(response.message.cars.length);
        }
    }

    const openModalProfile = () => {
        console.log('openModalProfile');
    };

    const emptyDropDown = (key) => {
        let newForm = {...form};
        newForm.filters[key] = '';
        setForm(newForm);
    }
    const onChange = async (key, value, parentField) => {
        let newForm = {...form};
        let countryCode = null;
        if(value.props){
            countryCode = value.props.children.props.code;
        }
        if(parentField){
            newForm[parentField][key] = value;
        }else{
            newForm[key] = value;
        }
        setForm(newForm);

        const keys =  Object.keys(availableLanguages);
        let lang = defaultLanguageKey;
        
        for(let i = 0; i < keys.length; i++){
            if(availableLanguages[keys[i]].countryCode === countryCode){
                lang = keys[i];
                break;
            }
        }

        i18n.changeLanguage(lang);
    }   

    const findRoutePathByKey = (key) => {
        const recursiveFind = (routeArray, key) => {
            for (const route of routeArray) {
                if (route.key === key) {
                    return route.path;
                }
                if (route.children) {
                    const foundPath = recursiveFind(route.children, key);
                    if (foundPath) return foundPath;
                }
            }
        };
        return recursiveFind(ROUTES, key);
    };

    const renderNavLink = ({ path, breadcrumb, key }, mobile=false) => {
        const isActive = key === 'home' ? location.pathname === '/' : UrlNavigatorSingleton.isInPage(key);

        return (
            <Link
                key={key}
                to={path}
                className={`${mobile ? 'block mb-5' : 'inline'} ${isActive ? 'isActiveHeader' : ''}`}
                onClick={() => {
                    if(path === '/buy')
                        pixelBuyPageFromHeader();
                    scrollToTopPage();
                }}
            >
                {
                    mobile ? 
                    <h4>{t(breadcrumb)}</h4>
                    :
                    <h5>{t(breadcrumb)}</h5>
                }
                
            </Link>
        );
    };

    const showMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    }

    const isUserLoggedIn = props.user && props.user.id;
    const username = /* Two first letters in uppercase */ isUserLoggedIn ? String(props.user.name).toUpperCase().slice(0, 2) : '';

    const mobile = isMobile(window);

    useEffect(() => {
        if(!mobile)
            setIsMenuOpen(true);

        let defaultIndex = 0;
        const languageToSearch = !availableLanguages[i18n.language] ? defaultLanguageKey :
        i18n.language.toUpperCase();
        
        //get index
        const keys = Object.keys(availableLanguages);
        for(let i = 0; i < keys.length; i++){
            if(availableLanguages[keys[i]].countryCode ==  languageToSearch){
                defaultIndex = i;
                break;
            }
        }
        onChange("language",languages[defaultIndex],"filters");
    },[mobile]);

    const languages = [];
    Object.keys(availableLanguages).forEach(key => {
        //languages.push(availableLanguages[key].countryCode);
        
        languages.push(
        <div className='country-icon-container-language'>
            <Flag className='' code={availableLanguages[key].countryCode} /></div>)
    });

    if(mobile){
        return (
            <>
                <div className={`${isMenuOpen ? 'nav-links z-50 p-5' : 'z-50 nav-links-closing'}`}>
                    <Close  onClick={() => showMenu()}/>
                    <Image className="mt-5 mb-5" width="170px" src={logo} />
                    <div className=''>
                        {
                            isUserLoggedIn ?   
                                <>
                                {ROUTES.filter(route => route.children).flatMap(route =>
                                    route.children.filter(child => child.mobileDropDown).map(child =>
                                        renderNavLink({ ...child, path: `${route.path}/${child.path}` },true)
                                    )
                                )}
                                </>    
                            :
                                ROUTES.filter(route => route.mobileDropDown).map(route => renderNavLink(route,true))
                        }
                        {ROUTES.filter(route => route.header).map(route => renderNavLink(route,true))}
                    </div>
                </div>
                    
                <div className='header-wrapper'>
                    <div className='header z-50'>
                        <div className='flex items-center justify-start'>
                            <img src={hamburgerMenu} className='h-[20px]' onClick={showMenu} />

                            <Link to='/'>
                                <img src={logo} className='h-[24px]' onClick={showMenu} />
                            </Link>
                        </div>
        
                        <div className='account-header-container'>
                            <Button className={'button no-margin'} style={{ marginLeft: 0 }}  onClick={() => UrlNavigatorSingleton.__toPage('/find-your-car')}>
                                <h5>{t('header_1')}</h5>
                            </Button>
                            {/* <div className='absolute' style={{height: "40px", bottom: "10%"}}>
                                <div className='divider-vertical' />   
                            </div> */}
                            <div style={{width : 30}} className="inline no-margin">
                                    
                                    <SelectDropDown 
                                        removeX={true}
                                        form={form}
                                        parentField={'filters'}
                                        keyItem={'language'}
                                        emptyDropDown={emptyDropDown}
                                        onChange={onChange}
                                        list={languages} 
                                        noMargin={true}
                                        isBorderless={true}
                                        hasDropDownArrow={false}
                                        allowSmallContainer={true}
                                    />  
                                </div>
                            {/* {isUserLoggedIn ?
                                <div className='inline-no-margin items-center justify-center !mr-2'>
                                    <Link className='inline-no-margin !mr-2' to={'/app/favorites'}>
                                        <FavoriteBorderOutlined className='favorite-icon' />
                                        <h5 className='favorite-count'>{favorites}</h5>
                                    </Link>
                                    <Link to='/app/account' className='inline-no-margin'>
                                        <h5 className='username-account-icon'>{username}</h5>
                                    </Link>
                                </div>
                                :
                                <Button className={'center-mobile-icon button fit icon-button'} style={{ marginLeft: 0 }} onClick={() => UrlNavigatorSingleton.__toPage('/signup')}>
                                    <Person2Outlined className='icon' />
                                </Button>
                            } */}
                        </div> 
                    </div>
                </div>
            </>);
    }
    

    return (
        <>
            <div>
                <div className='header fixed z-50'>
                    <div className='inline'>
                        <Link to='/' className='logo'>
                            <Image src={logo} height='100%' width='100px' />
                        </Link>
                    </div>
                    <>
                        <div className='nav-links'>
                            {ROUTES.filter(route => route.header).map(route => renderNavLink(route))}
                            {ROUTES.filter(route => route.children).flatMap(route =>
                                route.children.filter(child => child.header).map(child =>
                                    renderNavLink({ ...child, path: `${route.path}/${child.path}` })
                                )
                            )}
                        </div>
                        <div className='account-header-container'>
                                {isUserLoggedIn ?
                                    <div className='flex items-center justify-center'>
                                        <Link className='inline' to={'/app/favorites'}>
                                            <FavoriteBorderOutlined className='favorite-icon' />
                                            <h5 className='favorite-count'>{favorites}</h5>
                                        </Link>
                                        <Link to='/app/account' className='inline'>
                                            <h5 className='username-account-icon'>{username}</h5>
                                        </Link>
                                    </div>
                                    :
                                    <Button className={'button-white fit icon-button inline items-center'} style={{ marginLeft: 8 }} onClick={() => UrlNavigatorSingleton.__toPage('/signup')}>
                                        <Person2Outlined className='icon' />
                                        <h4 className=''>{t('login')}</h4>
                                    </Button>
                                }
                                <Button className={'button fit icon-button inline items-center'} style={{ marginLeft: 8 }} onClick={() => UrlNavigatorSingleton.__toPage('/find-your-car')}>
                                        <h4 className=''>{t('header_1')}</h4>
                                        
                                </Button>
                                <div style={{width : 30}} className="inline no-margin !mr-[30px] !mt-1">
                                    <div className='ml-1' style={{height: "80%", bottom: "10%"}}>
                                        <div className='absolute divider-vertical' />   
                                    </div>
                                    <SelectDropDown
                                        removeX={true}
                                        form={form}
                                        parentField={'filters'}
                                        keyItem={'language'}
                                        emptyDropDown={emptyDropDown}
                                        onChange={onChange}
                                        list={languages} 
                                        noMargin={true}
                                        isBorderless={true}
                                        hasDropDownArrow={true}
                                        allowSmallContainer={true}
                                    />  
                                </div>
                        </div>             
                    </> 
                </div>
            </div>
            <div className='header-margin' />
        </>
    );
});

function mapStateToProps(state) {
    return {
        user: state.user,
        router: state.router,
        app: state.app
    };
};

export default connect(mapStateToProps)(Header);
