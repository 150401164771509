import './index.scss';
import { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import Footer from "../../../components/Footer";
import { logo } from "../../../../assets/images";
import InputText from '../../../components/InputText';
import GoogleSignup from '../../../components/GoogleSignup';
import UserAPI from '../../../lib/User';
import UrlNavigatorSingleton from '../../../lib/UrlNavigator';
import NotificationTab from '../../../components/NotificationTab';
import { isValidEmail, isValidPassword, isValidName, generateRandomString } from '../../../lib/Helpers';
import { MetaTags } from 'react-meta-tags';
import NotificationHandlerSingleton from '../../../lib/NotificationHandler';
import Button from '../../../components/Button';    
import ReactPixel from 'react-facebook-pixel';
import { pixelUserSignup } from '../../../../utils/pixel';
import { withTranslation } from 'react-i18next';
import Header from '../../../components/Header';
import { withNavigate } from '../../../../utils/navigator';
import Loading from '../../../components/Loading';
import ResgisterForm from '../../../components/ResgisterForm';

class SignupPage extends Component{
    constructor(props){super(props); this.state = {register : true, isReadyToSubmit : false, resetPassword : false}}

    componentDidMount = async () => {
        //GoogleAnalyticsSingleton.pageview('signup');
        await this.__goToApp();
    }

    __goToApp = async () => {
        this.setState({isLoading : true});
        // Verify if User Exists in Cache
        let isAuth = await UserAPI.auth();
        const params = new URLSearchParams(window.location.search);
        if(isAuth){
            // Go to Signup Page
            this.setState({isLoading : false});
            
            const params = new URLSearchParams(window.location.search);
            let carId = params.get('car');
            let buy = null;
            if(carId){
                buy = params.get('buy') === "true";
            }
            
            if(carId){
                if(buy){
                    this.props.navigate(`/buy-form?id=${carId}`);
                }else{
                    this.props.navigate(`/car?id=${carId}&favorite=true`);
                }
            }else{
                this.props.navigate(`/`);
            }
        }else{
            this.setState({isLoading : false});
        }
    }

    resetPassword = async () => {
        try{
            this.setState({ isLoading: true });
            let res = await UserAPI.requestResetPassword({
                email : this.state.email
            })

            if(res.status != 200){ throw new Error(res.message)}
            NotificationHandlerSingleton.onNotification({message : "An Email has been sent to restore your password", code : 200})
            this.setState({isLoading : false})
        }catch(err){
            //Handle Err with UI
            console.log(err);
            this.setState({error : err, isLoading : false})
        }
    }

    register = async () => {
        try{
            this.setState({ isLoading: true });
            let res = await UserAPI.register({
                name : this.state.name,
                password : this.state.password,
                email : this.state.email
            })

            if(res.status != 200){ throw new Error(res.message)}
            //await GoogleAnalyticsSingleton.register({id : generateRandomString(5)});
 
            pixelUserSignup(this.state.name,this.state.email);

            await this.__goToApp();
        }catch(err){
            //Handle Err with UI
            console.log(err);
            this.setState({error : err, isLoading : false})
        }
    }

    login = async () => {
        try{
            this.setState({ isLoading: true });
            let res = await UserAPI.login({
                email : this.state.email,
                password : this.state.password
            })

            if(res.status != 200){ throw new Error(res.message)}
            await this.__goToApp();
        }catch(err){
            //Handle Err with UI
            console.log(err);
            this.setState({error : err, isLoading : false})
        }
    }

    changeView = () => {
        this.setState({register : !this.state.register})
    }

    changeResetPasswordView = () => {
        this.setState({resetPassword : !this.state.resetPassword, register : false})
    }

    onChange = (key, value) => {
        this.setState({[key] : value});
    }

    openForm = () => {
        this.setState({isLoading :false})
    }

    closeForm = () => {
        this.setState({isLoading :true})
    }

    renderLogin = () => {
        const {isLoading} = this.state;
        const isDisabled = isLoading || !this.isReadyToSubmitLogin();
        const { t } = this.props;
        return (
            <>
                <MetaTags>
                    <title>Signup | Free</title>
                    <meta name="description" content="Sign up Today! Access an exclusive library" />
                    <meta property="og:title" content="Sign up" />
                </MetaTags>
                <InputText 
                    form={this.state}
                    onChange={this.onChange} 
                    keyItem='email'
                    title={t('email')} placeholder={t('signup_4')} type={'email'}
                    isValid={isValidEmail(this.state['email'])}
                    error={t('signup_10')}
                />
                <InputText 
                    form={this.state}
                    onChange={this.onChange} 
                    keyItem='password'
                    isValid={isValidPassword(this.state['password'])}
                    error={t('signup_5')}
                    title={t('password')} placeholder={t('signup_6')} type={'password'}
                />
                    <a style={{cursor : 'pointer'}} onClick={this.changeResetPasswordView}>
                        <h5 className='text-left text-grey'>{t('signup_11')}</h5>
                    </a>
                <Button disabled={isDisabled} 
                    className={`button ${isDisabled ? "btn-disabled" : ""}`} 
                    style={{marginTop : 10, opacity : isLoading ? 0.7 : 1}} 
                    onClick={this.login}>
                    {isLoading ? <Loading/> : 
                    <h4>
                        {t('login')}
                    </h4>}
                </Button>
                <GoogleSignup goToApp={this.__goToApp}/>
                <div className='goToLoginContainer'>
                    <a onClick={this.changeView}>
                        <h5 className='text-center text-primary'>{t('signup_12')}</h5>
                    </a>
                </div>
            </>
        )
    }

    isReadyToSubmitLogin = () => {
        return (
            isValidEmail(this.state['email'])
            && isValidPassword(this.state['password'])
        )
    }

    renderForgotPassword = () => {
        const {isLoading} = this.state; 
        const isDisabled = isLoading || !isValidEmail(this.state['email']);
        const { t } = this.props;

        return (
            <>
                <InputText 
                    form={this.state}
                    onChange={this.onChange} 
                    keyItem='email'
                    isValid={isValidEmail(this.state['email'])}
                    error={t('signup_3')}
                    title={"E-mail"} placeholder={t('reset_2')} type={'email'}
                />
                <Button disabled={isDisabled} 
                    className={`button ${isDisabled ? "btn-disabled" : ""}`} 
                    style={{marginTop : 10, opacity : isLoading ? 0.7 : 1}} 
                    onClick={this.resetPassword}>
                    {isLoading ? <Loading/> : 
                    <h4>
                        {t('reset_1')}
                    </h4>}
                </Button>
                <h5 className='text-center grey'  style={{marginTop : 10}}>{t('reset_3')}</h5>
                <div className='goToLoginContainer'>
                    <a onClick={this.changeResetPasswordView}>
                        <h5 className='text-center text-primary'>{t('reset_4')}</h5>
                    </a>
                </div>
            </>
        )
        
    }

    renderRegister = () => {
        const { t } = this.props;

        return (
            <>
                <ResgisterForm goToApp={this.__goToApp}/>
                <div className='goToLoginContainer'>
                    <a onClick={this.changeView}>
                        <h5 className='text-center text-primary'>{t('signup_9')}</h5>
                    </a>
                </div>
            </>
        )
    }

    render = () => {
        const {isLoading, register, resetPassword} = this.state;
        return (
            <>
                <Header/>
                <NotificationTab/>
                <div className='signup-grid'>
                    <div className='singup-container'>
                        <div className='outside-container'>
                            <div className='inside-container'>
                                <div className='text-container-sign'>
                                    <div className='signup-container-main'>
                                        <img src={logo} className='logo-signup'/>
                                    </div>
                                    {
                                    resetPassword ? this.renderForgotPassword() :
                                    register ? this.renderRegister() : this.renderLogin()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='divider'/>
                <Footer/>
            </>
            )
    }
    
}

function mapStateToProps(state) {
    return {
        user : state.user
    };
}

export default connect(mapStateToProps)(withTranslation()(withNavigate(SignupPage)));