const logo = require('./logo.svg').default;
const stripePayments = require('./stripe.png');
const hero = require('./hero.png');
const hero_2 = require('./hero_2.png');
const checkMark = require('./checkmark.svg').default;
const crossMark = require('./crossmark.svg').default;
const circleCross = require('./circlecross.svg').default;
const checkMarkSolid = require('./checkmarksolid.svg').default;
const defaultCar = require('./default_car.svg').default;
const hamburgerMenu = require('./hamburgerMenu.svg').default;
const versicarHowItWorks1 = require('./versicarHowItWorks1.png');
const versicarHowItWorks2 = require('./versicarHowItWorks2.png');
const versicarHowItWorks3 = require('./versicarHowItWorks3.png');
const versicarHowItWorks4 = require('./versicarHowItWorks4.png');
const phone = require('./phone.svg').default;
const consumptionIcon = require('./consumptionIcon.svg').default;
const driveIcon = require('./driveIcon.svg').default;
const fuelIcon = require('./fuelIcon.svg').default;
const mileageIcon = require('./mileageIcon.svg').default;
const powerIcon = require('./powerIcon.svg').default;
const registrationDateIcon = require('./registrationDateIcon.svg').default;
const transmissionIcon = require('./transmissionIcon.svg').default;
const locationIcon = require('./locationIcon.svg').default;
const loadingIcon = require('./loadingIcon.svg').default;
const promotedIcon = require('./promoted.svg').default;
const filterIcon = require('./filterIcon.svg').default;
const carIcon = require('./carIcon.svg').default;
const linkIcon = require('./linkIcon.svg').default;
const selectedIcon = require('./selectedIcon.svg').default;
const selectedIconFalse = require('./selectedIconFalse.svg').default;
const doneIcon = require('./doneIcon.svg').default;
const heroBackground = require('./heroBackground.png');
const heroBackgroundMobile = require('./heroBackgroundMobile.png');

export {
    loadingIcon,
    hero,
    hero_2, 
    stripePayments,
    logo,
    checkMark,
    crossMark,
    circleCross,
    checkMarkSolid,
    defaultCar,
    hamburgerMenu,
    versicarHowItWorks1,
    versicarHowItWorks2,
    versicarHowItWorks3,
    versicarHowItWorks4,
    phone,
    consumptionIcon,
    driveIcon,
    fuelIcon,
    mileageIcon,
    powerIcon,
    registrationDateIcon,
    transmissionIcon,
    locationIcon,
    promotedIcon,
    filterIcon,
    carIcon,
    linkIcon,
    selectedIcon,
    selectedIconFalse,
    doneIcon,
    heroBackground,
    heroBackgroundMobile
}