import AppPage from './pages/AppPage';
import AccountPage from "./pages/AppPage/AccountPage";
import SignUpPage from "./pages/AppPage/SignUpPage";
import ResetPasswordPage from "./pages/AppPage/ResetPasswordPage";
import TermsAndConditions from "./pages/Utils/TermsAndConditions";
import {
    AccountCircleOutlined, FavoriteBorderOutlined, Inbox, InventoryOutlined, ReorderOutlined, StoreMallDirectory
} from '@mui/icons-material';
import LandingPage from './pages/LandingPage';
import ReviewsPage from './pages/ReviewsPage';
import EletricsPage from './pages/EletricsPage';
import BuyPage from './pages/BuyPage';
import CarPage from './pages/CarPage';
import BuyFormPage from './pages/FormPage/BuyFormPage';
import OrdersPage from './pages/AppPage/OrdersPage';
import HowItWorksPage from './pages/HowItWorksPage';
import FavoritesPage from './pages/AppPage/FavoritesPage';
import ThankYouPage from './pages/ThankYouPage';
import FindYourCarPage from './pages/FindYourCarPage';
import RequestsPage from './pages/AppPage/RequestsPage';

export const ROUTES = [
    {path : "/", key: 'home', breadcrumb: "route_1", page: <LandingPage />, header : false  },
    {path: "/buy", key: 'buy', breadcrumb: "route_2", page: <BuyPage />, header : true },
    {path: "/find-your-car", key: 'find-your-car', breadcrumb: "route_11", page: <FindYourCarPage />, header : false },
    // {path:  "/sell-form", key: 'sell-form', breadcrumb: "Sell Form", page: <SellFormPage />, header : true},
    {path : "/how-it-works", key: 'how-it-works', breadcrumb: "route_3", page: <HowItWorksPage />, header : true },
    {path : "/reviews", key: 'reviews', breadcrumb: "route_4", page: <ReviewsPage />, header : true },
    {path : "/eletrics", key: 'eletrics', breadcrumb: "route_5", page: <EletricsPage />, header : true },
    {path : "/car", key: 'car', breadcrumb: "Car", page: <CarPage />},
    {path : "/thank-you", key: 'thank-you', breadcrumb: "Thank You", page: <ThankYouPage /> },
    // {path : "/services", key: 'services', breadcrumb: "Services", page: <ServicesPage />, header : true },
    {path:  "/buy-form", key: 'buy-form', breadcrumb: "Buy Form", page: <BuyFormPage />},
    {
        path: "/app", key: 'app', breadcrumb: "App", page: <AppPage />,
        children: [
            { active : true, path: "account", key: 'account', breadcrumb: "route_9", mobileDropDown:true,page: <AccountPage /> , icon: <AccountCircleOutlined className='icon-leftBar'/> },
            { active : true, path: "favorites", key: 'favorites', breadcrumb: "route_6", mobileDropDown:true, page: <FavoritesPage /> , icon: <FavoriteBorderOutlined className='icon-leftBar'/> },
            { active : true, path: "orders", key: 'orders', breadcrumb: "route_7", page: <OrdersPage/> , icon: <InventoryOutlined className='icon-leftBar'/> },
            { active : true, path: "requests", key: 'requests', breadcrumb: "route_12", page: <RequestsPage/> , icon: <Inbox className='icon-leftBar'/> },
            // { active : true, path: "cars", key: 'cars', breadcrumb: "route_8", page: <MyCarsForSalePage /> , icon: <StoreMallDirectory className='icon-leftBar'/> },
        ]
    },
    { path: "signup", key: 'signup', breadcrumb: "route_13", page: <SignUpPage />, mobileDropDown: true },
    { path: "resetPassword", key: 'resetPassword', breadcrumb: "Reset Password", page: <ResetPasswordPage /> },
    { path: "terms-and-conditions", key: 'termsAndConditions', breadcrumb: "Terms and Conditions", page: <TermsAndConditions /> },
];
