/* Create Brand Picker Modal */
/* Should be able to select a brand from a list of brands */
/* Should be able to search for a brand */
/* Should be able to create a new brand */

import { connect } from "react-redux";
import "./index.scss";
import 'react-calendar/dist/Calendar.css';
import { withTranslation } from "react-i18next";
import { isMobilePhoneValid, isValidEmail, renderInput, renderItemList, renderPhoneNumber, truncateStringByLimitWords } from "../../lib/Helpers";
import { Component } from "react";
import { filtersSearch, getFilterValues } from "../../../filters/search";
import { Button } from "@mui/material";
import APIConnection from "../../api"
import ContainedLoading from "../ContainedLoading";
import { withNavigate } from "../../../utils/navigator";
import Loading from "../Loading";
import { isMobile } from "../../../utils/utils";
import PhoneNumber from "../PhoneNumber";
import { isValidPhoneNumber } from "react-phone-number-input";

const mandatoryKeys = [
    'url',
    'email',
    'phoneNumber'
]
const formDefault = {
    'url': '',
    'email': '',
    'phoneNumber': ''
}
class CarFindForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            form: formDefault,
            disableButton:true,
        }
    }

    onChange = (key, value, parentField, options={variable : false, array : false}) => {
        this.setState(prevState => ({
            form: {
                ...this.state.form,
                [key]: value
            }
        }),() => {
            let disable = false;
            for(let i = 0; i < mandatoryKeys.length; i++){
                if(this.state.form[mandatoryKeys[i]] === null || this.state.form[mandatoryKeys[i]] === undefined 
                    || this.state.form[mandatoryKeys[i]] == ''){
                    disable = true;
                    break;
                }
            }
            
            if(!disable)
                disable = !this.state.form['email'] || !isValidEmail(this.state.form['email']) ||
                            !this.state.form['phoneNumber'] ||
                            !isMobilePhoneValid(this.state.form['phoneNumber']);
            
            if(this.state.disableButton != disable){
                this.setState({disableButton: disable});
            }
        });
    }

    sendRequest = async () => {
        const { t, setLoading } = this.props;
        const form = this.state.form;
        setLoading(true);
        try{
            const response = await APIConnection.createRequestFind({form});

            if(response.status !== 200){
                alert(t('request_4') + "\n" + response.message);
                setLoading(false,true);
                this.setState({disableButton: false});
            }else{
                setLoading(false,false);
                this.props.navigate('/car?id='+response.message.id);
                return;
            }
        }catch(err){
            alert(t('request_5'));
            setLoading(false,true);
            this.setState({disableButton: false});
        }
    }

    render = () => {
        const { user, t } = this.props;
        const { loading } = this.state;
        
        const mobile = isMobile(window);
        return (
            <div className={`car-find-form-container`}>
                <div className="flex flex-col !items-start">
                    <div className="flex flex-row !items-start w-full">
                        <span className="inline">
                            {mobile ? 
                            <h3>{t('find_your_car_6')}</h3> :
                            <h2>{t('find_your_car_6')}</h2>
                            } 
                            {<h5 className='!text-red-600 !pt-1 !pb-0 !pl-2 !pr-0'>*</h5> }
                        </span>
                        {renderInput({title : '', keyItem : 'url', placeholder : t('find_your_car_7'),
                            form:this.state.form,
                            onChange: this.onChange
                        })}
                    </div>
                    <div className={`${mobile ? '': 'grid-50'}`}>
                        {renderInput({type:'email',title : t('email'), keyItem : 'email', placeholder : t('email'),
                            form:this.state.form,onChange: this.onChange,isRequired: true, defaultValue: user.email,
                            isValid:isValidEmail(this.state.form['email']), error:t('signup_3')
                        })}
                        {renderPhoneNumber({title : t('phone_number'), keyItem : 'phoneNumber',
                            form:this.state.form,onChange: this.onChange,isRequired: true,
                            isValid: isMobilePhoneValid(this.state.form['phoneNumber']), 
                            error:t('wrong_phone_number'),
                        })}
                        {/* {renderInput({type:'tel',title : t('phone_number'), keyItem : 'phoneNumber', placeholder : t('phone_number'),
                            form:this.state.form,onChange: this.onChange,isRequired: true
                        })} */}
                    </div>
                    <Button className={`button ${this.state.disableButton ? 'btn-disabled' : ''} fit ml-5`} onClick={() => {
                        if(!this.state.disableButton)
                            this.sendRequest();
                        }}>
                        <h6>{t('request_2')}</h6>
                    </Button>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user : state.user,
    };
}

export default connect(mapStateToProps)(withTranslation()(withNavigate(CarFindForm)));