import './index.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';

class InputText extends Component{
    constructor(props){
        super(props); 
        this.state = {isFocused : true}

        this.ref = React.createRef();
    }

    onChange = (e) => {
        const { keyItem, parentField, defaultValue, defaultOnEmpty } = this.props;
        let __normalizedText = e.target.value;
        if(keyItem == ('email' || 'password')){
            __normalizedText = __normalizedText.trim();
        }

        if(!__normalizedText && defaultOnEmpty)
            __normalizedText = defaultValue;

        if(parentField){
            this.props.onChange(keyItem, __normalizedText, parentField);
        }else{
            this.props.onChange(keyItem, __normalizedText);
        }
    }

    componentDidMount = () => {
        const { keyItem, defaultValue, parentField} = this.props;
        if(defaultValue){
            if(parentField){
                this.props.onChange(keyItem, defaultValue, parentField);
            }else{
                this.props.onChange(keyItem, defaultValue);
            }
        }
    }

    handleBlur = () => {
        this.setState({isFocused : false})
    }
    render = () => {
        const { isFocused } = this.state;
        var { placeholder, title, type, error, isValid=true, form, parentField,keyItem,defaultValue, isRequired, textArea, rows, cols } = this.props;
        isValid = isFocused ? true : isValid;
        let inputPresent = null; 
        if(form){
            inputPresent = parentField ? form[parentField][keyItem] : form[keyItem];
        }
        if(!inputPresent){
            inputPresent = defaultValue;
        }

        return (
            <div className='inputTextDiv'>    
                <div className='text-left'>
                    <h5 className={`inline no-margin title bold-text ${!isValid ? 'error-text' : ''}`}>
                        {title}
                    </h5>                    
                    {isRequired ? <h5 className={`inline no-margin !text-red-600`}>*</h5> : <></>}
                </div>
                {
                    textArea ?
                    <>
                        <br/>
                        <textarea ref={this.ref} className={`${!isValid ? 'error-box' : ''}`}
                            onChange={this.onChange} 
                            value={inputPresent}
                            onBlur={this.handleBlur}
                            type={type} 
                            inputMode={type}
                            placeholder={placeholder}
                            rows={rows}
                            cols={cols}
                            />
                    </>
                    :
                    <input ref={this.ref} className={`${!isValid ? 'error-box' : ''}`}
                        onChange={this.onChange} 
                        value={inputPresent}
                        onBlur={this.handleBlur}
                        type={type} 
                        inputMode={type}
                        placeholder={placeholder}>
                    </input>
                }
                
                {!isValid ? <h5 className='input-error'>{error}</h5> : null}
            </div>
            )
    }
    
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
  }

  export default connect(mapStateToProps)(InputText);
