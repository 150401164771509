import './index.scss';
import Image from "../Image";
import { hero } from "../../../assets/images";
import { Button } from '@mui/material';


const ImagedCard = (props) => {

    const {title,text,onClick} = props;
    return (
        <div className='cant-find-container'>
            <div className='cant-find-background'></div>
            <Image src={hero} className={'cant-find-image'} alt='Imagem de legalização de carro importado' />
            <div className='center flex-col z-10 !justify-evenly'>
                <h2 className='text-white'>
                    {title}
                </h2>
                <div className='button-white fit ml-5' onClick={() => {
                    if(onClick) 
                        onClick();
                    }}>
                {typeof(text) === 'string'?    
                    <h5>
                        {text}
                    </h5>
                    :
                    <>{text}</>
                }
                </div>
            </div>
        </div>
    );
}

export default ImagedCard;