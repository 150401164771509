import { Component } from 'react';
import { connect } from 'react-redux';
import './index.scss';
import { MetaTags } from 'react-meta-tags';
import ConnectionAPI from '../../../api';
import CarsListVertical from '../../../sections/CarsList/Vertical';
import { withTranslation } from 'react-i18next';
import ContainedLoading from '../../../components/ContainedLoading';

const pageSize = 10;

class RequestsPage extends Component{
    constructor(props){
        super(props);
        this.state = {
            requests : [],
            filters: {
                offset: 0,
                amount: 20
            },
            cars: [],
            loading: false,
            currentPage: 1,
            totalPages: 0
        }
    }

    componentDidMount = () => {
        this.getRequests();
    }
    
    getRequests = async ({page}={page : 1}) => {
        this.setState({ loading: true });
        const { filters } = this.state;
        const { user } = this.props;
        const response = await ConnectionAPI.getRequests({filters});
        if(response.status === 200){
            let { cars } = response.message;
            const totalPages = Math.ceil(cars.length / pageSize);
            const paginatedRequests = cars.slice((page - 1) * pageSize, page * pageSize);
            cars = cars.map(request => ({...request.car, ...request}));
            this.setState({ cars, orders: paginatedRequests, loading: false, currentPage: page, paginatedRequests,totalPages, total: cars.length });
        } else {
            this.setState({ loading: false });
        }
    }

    onChangePage = (event, page) => {
        this.getRequests({ page });
    }
    
    render = () => {
        const { user, t } = this.props;
        const { total, cars, loading, currentPage, totalPages } = this.state;
        if(!user.id){return null};
        return (
            <div className='page'>
                <MetaTags>
                    {/* <title>Os meus pedidos</title> */}
                    <meta name="description" content="Manage your Account" />
                    <meta property="og:title" content="Account" />
                </MetaTags>
                <div className='container'>
                    <CarsListVertical
                        showCantFindCard={false}
                        title={t('order_1')}
                        total={total}
                        cars={cars}
                        isOrder={false}
                        loading={loading}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onChangePage={this.onChangePage}
                    />
                </div>
            </div>
        )
    }
    
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
  }
  
export default connect(mapStateToProps)(withTranslation()(RequestsPage));