import React, { useState, useEffect } from 'react';
import { defaultCar } from '../../../assets/images';
import { Carousel } from 'react-responsive-carousel';
import * as ReactDOM from 'react-dom';
import { ArrowBack, ArrowForward, ArrowLeft } from '@mui/icons-material';
import { createRoot } from 'react-dom/client';
import './index.scss';
const ImageDisplayer = ({ imageUrls, imageClasses, imageOnClick = () => {}, showThumbs=false, showStatus=false, showArrows=false,showIndicators=false }) => {
  const [images, setImages] = useState([]);
  const [firstImage, setFirstImage] = useState(false);

  const displaySliderBottomButtons = (i,e) => {
    if(!document || !showThumbs)
      return;
    const leftButton = document.getElementsByClassName('control-arrow control-prev');
    const rightButton = document.getElementsByClassName('control-arrow control-next');
    const iconContainer = document.createElement("div"); // Create a container
      iconContainer.classList.add("action-button");
      Object.assign(iconContainer.style, {
        position: "absolute",
        top: "0",
        left: "0",
        marginTop:"-5px",
        border:"none"
      });
    if(leftButton && leftButton.length == 2 && leftButton[1].children.length == 0){
      const root = createRoot(iconContainer);
      root.render(<div className='thumb-icon'><ArrowBack className="" /></div>);
      leftButton[1].prepend(iconContainer); // Append it
    }
    if(rightButton && rightButton.length == 2 && rightButton[1].children.length == 0){
      const root = createRoot(iconContainer);
      root.render(<ArrowForward className="" />);
      rightButton[1].prepend(iconContainer); // Append it
    }
  }

  useEffect(() => {
    setImages([]); // Reset images immediately on URL change
    const loadImages = async () => {

      const firstImageLoaded = await new Promise((resolve) => {
        const img = new Image();
        const url = imageUrls[0];
        img.src = url;
        img.onload = () => resolve(url);
        img.onerror = () => resolve(defaultCar);
      });

      setImages([firstImageLoaded]);
      setFirstImage(true);
    };

    loadImages();
  }, [imageUrls]);

  useEffect(() => {
    const loadImages = async () => {
      const loadedImages = await Promise.all(
        imageUrls.slice(1,imageUrls.length).map((url) => 
          new Promise((resolve) => {
            const img = new Image();
            img.src = url;
            img.onload = () => resolve(url);
            img.onerror = () => resolve(defaultCar);
          })
        )
      );
      loadedImages.unshift(images[0]);
      setImages(loadedImages);
      displaySliderBottomButtons();
    }
    if(firstImage){
      loadImages();
      setFirstImage(false);
    }

  },[firstImage]);

  if (images.length == 0) {
    // Render shimmer while images are loading
    return (
      <div className="carousel">
        <div className="shimmer" style={{ height: "300px", width: "100%" }}></div>
      </div>
    );
  }

  if(imageUrls.length > 1){
    return (
      <Carousel className='carousel' showArrows={showArrows} showStatus={showStatus} showThumbs={showThumbs} showIndicators={showIndicators}
      swipeable={true} 
      emulateTouch={true} 
      infiniteLoop={true}
      onChange={displaySliderBottomButtons}
      onClickThumb={displaySliderBottomButtons}
      preventMovementUntilSwipeScrollTolerance={true}>
        {images.map((src, index) => (
          <div key={src}  onClick={imageOnClick}>
            <img src={src} alt={`Imagem de carro importado da europa ${index}`} className={imageClasses}/>
          </div>
        ))}
      </Carousel>
    );
  }
  return <img key={images[0]} src={images[0]} alt={`Imagem de carro importado da europa`} className={imageClasses} />;
};

export default ImageDisplayer;
