/* Create React class for Footer */
import React from 'react';
import './index.scss';
import { Link } from 'react-router-dom';
import Image from '../Image';
import {logo} from '../../../assets/images';
import { useTranslation } from 'react-i18next';
import { isMobile } from '../../../utils/utils';

const productLinks = [
    {name : 'footer_2', link : '/'},
    {name : 'footer_3', link : '/buy'}
]

const companyLinks = [
    {name : 'footer_9', link : '/#faq'},  
    {name : 'footer_10', link : '/terms-and-conditions'}   
]

const socialLinks = [
    {name : 'footer_12', link : 'https://www.facebook.com/versicar'},  
    {name : 'footer_13', link : 'https://www.tiktok.com/@versicar_'},  
    {name : 'footer_14', link : 'https://www.instagram.com/versicar/'},  
    // {name : 'footer_15', link : '/linkedin'}
]


const Footer = (props) => {
    const { t, i18n } = useTranslation();

    // const { boot, shutdown, hide, show, update } = useIntercom();
    const mobile = isMobile(window);

    return (
        <footer className={`${mobile ? '' : 'grid-50'} container`}>
            <div className={`${mobile ? 'grid-50' : 'grid-50'} small-container`}>
                <div>
                    <Image src={logo} width={100}/>
                    <h4 className='small-container grey'>&copy; 2025 Versicar</h4>
                </div>
            </div>
           
            <div className='grid-33'>
                <div>
                    <h4 className='small-container'>{t('footer_17')}</h4>
                    {productLinks.map((link, index) => {
                        return <Link key={index} to={link.link}><h4 className='grey'>{t(link.name)}</h4></Link>
                    })}
                </div>
                <div>
                    <h4 className='small-container'>{t('footer_18')}</h4>
                    {companyLinks.map((link, index) => {
                        return <Link key={index} to={link.link}><h4 className='grey'>{t(link.name)}</h4></Link>
                    })} 
                </div>
                <div>
                    <h4 className='small-container'>{t('footer_19')}</h4>
                    {socialLinks.map((link, index) => {
                        return <Link key={index} to={link.link}><h4 className='grey'>{t(link.name)}</h4></Link>
                    })}
                </div>
            </div>
        </footer>
    );
}

export default Footer;