import './index.scss';
import { Component } from 'react';
import { connect } from 'react-redux';

class InformationCard extends Component{
    constructor(props){
        super(props);
    }

    render = () => {
        let { center,title, subTitle, content, smallTitle, maxHeight, greyBackground } = this.props;

        if(!maxHeight)
            maxHeight = 300;

        return (
            <div className={`${greyBackground ? 'box-background' : ''} box-container small-container ${center ? " center " : ''}`}  
            
            style={{minHeight : 100, maxHeight: maxHeight, minWidth: 100}}>
                {title ? (
                typeof title !== 'object' ? 
                    smallTitle ? 
                        <h5 className='primary-text'>{title}</h5>
                         : 
                        <h3 className='primary-text'>{title}</h3>
                    : 
                    title
                    ) : (
                        <></>
                )}
                <div className='mb-5'>
                    {
                        typeof(subTitle) !== 'object'  ? 
                        subTitle
                        :
                        <h3>{subTitle}</h3>
                    }
                </div>
                <p>{content}</p>
            </div>
        )
    }
    
}


export default InformationCard;
