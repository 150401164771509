import './index.scss';
import { Component } from 'react';
import { Pagination } from '@mui/material';
import { withTranslation } from 'react-i18next';
import { isMobile } from '../../../utils/utils.js';
import { getObjectFromQueryString } from '../../lib/Helpers.js';

class PaginationContainer extends Component {
    handlePageChange = (event, value) => {
        const { onChangePage } = this.props;
        onChangePage(event, value);
    };


    componentDidMount = () => {
        const filters = getObjectFromQueryString({queryString : window.location.search});
        if(filters.page)
            this.setState({page: filters.page});
    }

    render() {
        let { page, pages, t } = this.props;
        const isFirstPage = page === 1;
        const isLastPage = page === pages;
        const mobile = isMobile(window);

        if(pages === 0)
            page = 0;
        return (
            <div className={`${mobile ? 'flex items-center flex-col' : 'flex items-center justify-start'}`}>
                <div className=''>
                    <h5><span className='grey inline'>{t('pagination_1')}</span> {page} <span className='grey inline'>{t('pagination_2')}</span> {pages}</h5>
                </div>
                <center className={`${mobile ? 'ml-2.5' : ''}`}>
                    <Pagination
                        page={page}
                        onChange={this.handlePageChange}
                        count={pages}
                        shape="rounded"
                        disabled={pages <= 1}
                    />
                </center>
                {mobile
                ?
                <></>
                :
                <div></div>
                }
            </div>
        );
    }
}

export default (withTranslation()(PaginationContainer));