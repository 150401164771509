import { Component } from 'react';
import { connect } from 'react-redux';
import './index.scss';
import { MetaTags } from 'react-meta-tags';
import UserAPI from '../../../lib/User';
import NotificationHandlerSingleton from '../../../lib/NotificationHandler';
import Button from '../../../components/Button';
import UrlNavigatorSingleton from '../../../lib/UrlNavigator';
import { withTranslation } from 'react-i18next';

class AccountPage extends Component{
    constructor(props){
        super(props);
        this.state = {
        
        }
    }

    
    renderInfo = ({key, value,valueClass = ''}) => {
        return (
            <div className='fifty'>
                <div>
                    <h5 className='grey' style={{margin : 0}}> {key}</h5>
                </div>
                <div className='text-right break-words'>
                    <h4 className={valueClass}> {value}</h4>
                </div>
            </div>
       
        )
    }

    recoverPassword = async () => {
        await UserAPI.requestResetPassword({email : this.props.user.email});
        // Inform on Modal
        NotificationHandlerSingleton.onNotification({message : "Password Recovery Email Sent", status : "success"})
    }

    logout = () => {
        /* Logout   */
        /* Remove User from Cache */
        UserAPI.logout();
        /* Redirect to Home */
        UrlNavigatorSingleton.__toPage('/');
    }
    
    render = () => {
        const { user, t } = this.props;
        if(!user.id){return null};
        return (
            <div className='page'>
                <MetaTags>
                    <title>Account</title>
                    <meta name="description" content="Manage your Account" />
                    <meta property="og:title" content="Account" />
                </MetaTags>
                <div className='container'>
                    {/* User Data*/}
                    {this.renderInfo({key : t('name'), value : user.name})}
                    {this.renderInfo({key : t('email'), value : user.email})}
                    {this.renderInfo({key : t('user_id'), value : user.id})}
                    {this.renderInfo({key : t('balance'), value : user.balance + '€',
                        valueClass: user.balance > 0 ? 'green' : ''})}
                    <div className='divider'/>
                    <div className='fifty'>
                        <div>
                            <h4 className='grey' style={{margin : 0}}> {t('account_1')} </h4>
                        </div>
                        <a style={{cursor : 'pointer'}} onClick={this.recoverPassword} className='text-right'>
                            <h4 className='link'> {t('account_2')} </h4>
                        </a>
                    </div>
                    <div className='divider'/>
                    <div className='sort-right'>
                        <Button className={'button fit'} onClick={() => this.logout()}>
                            <h4 className='grey'> {t('account_3')} </h4>
                        </Button>
                    </div>
                </div>       
            </div>
        )
    }
    
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
  }

export default connect(mapStateToProps)(withTranslation()(AccountPage));