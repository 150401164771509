import { useEffect, useState } from 'react';
import './index.scss';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { useTranslation } from 'react-i18next';


// const CountrySelect = ({ value, onChange, labels, ...rest }) => (
//     <select
//       {...rest}
//       value={value}
//       onChange={event => onChange(event.target.value || undefined)}>
//       <option value="">
//         {labels['ZZ']}
//       </option>
//       {getCountries().map((country) => (
//         <option key={country} value={country}>
//           {labels[country]} +{getCountryCallingCode(country)}
//         </option>
//       ))}
//     </select>
//   )
  
//   CountrySelect.propTypes = {
//     value: PropTypes.string,
//     onChange: PropTypes.func.isRequired,
//     labels: PropTypes.objectOf(PropTypes.string).isRequired
//   }

const PhoneNumber = (props) => {
    const { t, i18n } = useTranslation();

    let { onChange,form, isRequired, title = t('phone_number'), placeholder = t('phone_number_1'), parentField, keyItem, 
        isValid,
        //defaultValue, defaultOnEmpty,
        error} = props;

    const change = (newValue) => {
        let phone = newValue;
        // if(!newValue && defaultOnEmpty){
        //     phone = defaultValue;
        //     newValue = "+351";
        // }
        // if(phone && phone.length <= 4 && phone.charAt(0) == '+'){
        //     phone = '';
        // }
        onChange(keyItem, phone, parentField);
    }

    let currentValue = ""; 
    if(form){
        currentValue = parentField ? form[parentField][keyItem] : form[keyItem];
    }
    // useEffect(() => {
    //     let {form,parentField, keyItem,defaultValue} = props;

    //     let currentValue = parentField ? form[parentField][keyItem] : form[keyItem];
    //     if(!currentValue){
    //         change(defaultValue);
    //     }
    //   }, []);
   

    return (
    <div className='inputTextDiv'>
        <div className='text-left'>
            <h5 className={`inline no-margin title bold-text ${!isValid ? 'error-text' : ''}`}>
                {title}
            </h5>                    
            {isRequired ? <h5 className={`inline no-margin !text-red-600`}>*</h5> : <></>}
        </div>
        <div>
        {/* <CountrySelect
        labels={en}
        value={country}
        onChange={setCountry}/> */}

        {/* country={country} */}
            <div className={`${!isValid ? 'input-error-box' : ''}`}>
                <PhoneInput
                    placeholder={placeholder}
                    defaultCountry="PT"
                    value={currentValue}
                    onChange={change}/>
            </div>
        </div>
        {!isValid ? <h5 className='input-error'>{error}</h5> : null}
    </div>
    )
}

export default PhoneNumber;