import './index.scss';
import { Component, lazy, Suspense } from 'react';
import PaginationContainer from '../../../components/Pagination';
import Loading from '../../../components/Loading';
import { KeyboardArrowDown } from '@mui/icons-material';
import SelectDropDown from '../../../components/DropDown/Select';
import { sortOptions } from '../../../../filters/sort';
import { withTranslation } from 'react-i18next';
import ContainedLoading from '../../../components/ContainedLoading';
import ImagedCard from '../../../components/ImagedCard';
import UrlNavigatorSingleton from '../../../lib/UrlNavigator';

const CardHorizontal = lazy(() => import('../../../components/Card/Horizontal'));

class CarsListVertical extends Component {

    constructor(props) {
        super(props);
        const { t } = this.props;

        this.state = {
            form : {
                filters : {
                    sort : Array.from(sortOptions(t).keys())[0],
                }
            }
        };
    }
   
   onChange = (key, value, parentField) => {
        const { form } = this.state;
        const { t } = this.props;
        form[parentField][key] = value;
        this.setState({ form });
        this.props.changeSort(sortOptions(t).get(value));
    }

    emptyDropDown = (key) => {
        const { form } = this.state;
        form.filters[key] = '';
        this.setState({ form });
    }

    render() {
        const { loading, cars, title, total, currentPage, totalPages, onChangePage, isOrder, t, initialSortIndex,showCantFindCard = true } = this.props;
        const { form } = this.state;
        const hasCars = cars && cars.length > 0;
        
        if(initialSortIndex){
            form.filters.sort = Array.from(sortOptions(t).keys())[initialSortIndex];
        }
            
        if(!hasCars)
            <p>{t('buy_2')}</p>
        if(loading)
            return <ContainedLoading/>

        window.addEventListener("popstate", () => {
            window.location.reload(); // Refresh the page when back is clicked
        });

        return (
            <div className='small-container'>                
                <div className='scroll-container-car'>
                    <div className='small-container flex'>
                        <div>
                            <h2>{title}</h2>
                        </div> 
                    </div>
                    <div>
                    {
                        hasCars ?
                        <div className=''>
                            <div className='inline'>
                                <div className='flex justify-start items-center gap-1'>
                                    <h5 className='font-bold'>{total}</h5>
                                    <h5 className='grey'>{t('results')}</h5>
                                    <div className='divider-vertical'/>
                                </div>
                            </div>
                            <div className='inline no-margin'>
                            <SelectDropDown 
                                    id={1}
                                    removeX={true}
                                    form={form}
                                    parentField={'filters'}
                                    keyItem={'sort'}
                                    emptyDropDown={this.emptyDropDown}
                                    onChange={this.onChange}
                                    list={Array.from(sortOptions(t).keys())} 
                                    inline={true} 
                                />
                            </div>
                        </div>
                        :
                        <></>
                    }
                    </div>
                    <div className=''>
                        {hasCars ? cars.map((car, index) => (
                            <Suspense fallback={<ContainedLoading/>} key={index}>
                                <CardHorizontal car={car} isOrder={isOrder} />
                                {index == 2 && showCantFindCard ?
                                <ImagedCard title={t('cant_find_1')} text={t('contact_us')} onClick={() => UrlNavigatorSingleton.__toPage(`/find-your-car`)} />
                                :
                                <></>
                                }
                            </Suspense>
                        )) : <p>{t('buy_2')}</p>}
                    </div>
                </div>
                <PaginationContainer page={currentPage} pages={totalPages} onChangePage={onChangePage} />
            </div>
        );
    }
}

export default (withTranslation()(CarsListVertical));
